import { createStore } from 'vuex';
import axios from 'axios';
import router from "@/router";
export default createStore({
  state: {
    api: 'https://service.bemekelektrik.com',
    grant_type: 'password',
    client_id: 2,
    client_secret: 'lqfXOINdZ1OPR428H2NNG2z546ADtHeSqYImQjod',
    scope: '',
    isAuth: false,
    token:null,
    user:null
  },
  getters: {
    auth(state) {
      return state.isAuth;
    },
    user(state) {
      return state.user;
    }
  },
  mutations: {
    setAuth(state, auth) {
      state.isAuth = auth;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    }
  },
  actions: {
    async authenticate({ state,commit },form) {
      const formData = new FormData();
      formData.append('username', form.username);
      formData.append('password', form.password);
      formData.append('grant_type', state.grant_type);
      formData.append('client_id', state.client_id);
      formData.append('client_secret', state.client_secret);
      formData.append('scope', state.scope);
      await axios.post(`${state.api}/oauth/token`, formData).then(response => {
        commit('setToken', response.data.access_token);
        localStorage.setItem('token',response.data.access_token);
      }).catch(error => {
        return Promise.reject(error);
      });
      await axios.get(`${state.api}/api/v1/auth-user`,{
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      }).then(response => {
        if (response.data.role[0].name !== 'root'){
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          commit('setToken', null);
          commit('setUser', null);
          commit('setAuth', false);
          return Promise.reject('Yetkisiz Giriş!');
        }else{
          commit('setUser', response.data);
          localStorage.setItem('user', JSON.stringify(response.data));
        }
      }).catch(error => {
        return Promise.reject(error);
      });
      commit('setAuth', true);
      return Promise.resolve('Giriş Başarılı!',200);
    },
    async refreshToken({commit,state}) {
      let isToken = localStorage.getItem('token');
      let isUser = localStorage.getItem('user');
      commit('setToken', isToken);
      commit('setUser', JSON.parse(isUser));
      axios.get(`${state.api}/api/v1/auth-user`,{
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      }).then(response => {
        commit('setAuth', true);
      }).catch(error => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        router.push('/login');
      });
    },
    logout({commit}) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      commit('setToken', null);
      commit('setUser', null);
      commit('setAuth', false);
    }
  },
  modules: {}
});
