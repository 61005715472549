import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/auth";
import login from "@/views/login.vue";
import Dashboard from "@/views/dashboard.vue";
import HomeSliderList from "@/views/WebsiteManagement/HomeSlider/HomeSliderList.vue";
import NewHomeSlider from "@/views/WebsiteManagement/HomeSlider/NewHomeSlider.vue";
import ProducList from "@/views/ProductManagement/Products/ProducList.vue";
import CategoryList from "@/views/ProductManagement/Category/CategoryList.vue";
import NewCategory from "@/views/ProductManagement/Category/NewCategory.vue";
import EditCategory from "@/views/ProductManagement/Category/EditCategory.vue";
import SubCategoryList from "@/views/ProductManagement/SubCategory/SubCategoryList.vue";
import NewSubCategory from "@/views/ProductManagement/SubCategory/NewSubCategory.vue";
import EditSubCategory from "@/views/ProductManagement/SubCategory/EditSubCategory.vue";
import BrandList from "@/views/ProductManagement/Brand/BrandList.vue";
import NewBrand from "@/views/ProductManagement/Brand/NewBrand.vue";
import TaxList from "@/views/ProductManagement/Taxes/TaxList.vue";
import NewTax from "@/views/ProductManagement/Taxes/NewTax.vue";
import NewCatalog from "@/views/WebsiteManagement/Catalog/NewCatalog.vue";
import CatalogList from "@/views/WebsiteManagement/Catalog/CatalogList.vue";
import EditInstitutional from "@/views/WebsiteManagement/Institutional/EditInstitutional.vue";
import EditContact from "@/views/WebsiteManagement/Contact/EditContact.vue";
import ContactFormList from "@/views/WebsiteManagement/ContactForm/ContactFormList.vue";
import ShowContactForm from "@/views/WebsiteManagement/ContactForm/ShowContactForm.vue";
import IbanList from "@/views/WebsiteManagement/İbans/IbanList.vue";
import NewIban from "@/views/WebsiteManagement/İbans/NewIban.vue";
import ContractsList from "@/views/WebsiteManagement/Contracts/ContractsList.vue";
import NewContract from "@/views/WebsiteManagement/Contracts/NewContract.vue";
import ShowContract from "@/views/WebsiteManagement/Contracts/ShowContract.vue";
import NewProduct from "@/views/ProductManagement/Products/NewProduct.vue";
import EditProduct from "@/views/ProductManagement/Products/EditProduct.vue";
import ShowProduct from "@/views/ProductManagement/Products/ShowProduct.vue";
import ProductImageList from "@/views/ProductManagement/ProductImages/ProductImageList.vue";
import NewProductImage from "@/views/ProductManagement/ProductImages/NewProductImage.vue";
import WebOrderList from "@/views/OrderManagement/WebOrder/Order/WebOrderList.vue";
import EditWebOrder from "@/views/OrderManagement/WebOrder/Order/EditWebOrder.vue";
import ShowWebOrder from "@/views/OrderManagement/WebOrder/Order/ShowWebOrder.vue";
import Testpage from "@/views/testpage.vue";

const routes = [
  {
    path: '/',
    name: '/',
    component: Dashboard,
    meta:{'auth': true},
  },
  {
    path: '/home-slider',
    name: 'Home Slider',
    component: HomeSliderList,
    meta:{'auth': true},
  },
  {
    path: '/new-home-slider',
    name: 'New Home Slider',
    component: NewHomeSlider,
    meta:{'auth': true},
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: CatalogList,
    meta:{'auth': true},
  },
  {
    path: '/new-catalog',
    name: 'New Catalog',
    component: NewCatalog,
    meta:{'auth': true},
  },
  {
    path: '/edit-institutional/:id',
    name: 'Edit Institutional',
    component: EditInstitutional,
    meta:{'auth': true},
  },
  {
    path: '/edit-contact/:id',
    name: 'Edit Contact',
    component: EditContact,
    meta:{'auth': true},
  },
  {
    path: '/contact-forms',
    name: 'Contact Forms',
    component: ContactFormList,
    meta:{'auth': true},
  },
  {
    path: '/show-contact-form/:id',
    name: 'Show Contact Form',
    component: ShowContactForm,
    meta:{'auth': true},
  },
  {
    path: '/ibans',
    name: 'İbans',
    component: IbanList,
    meta:{'auth': true},
  },
  {
    path: '/new-iban',
    name: 'New Iban',
    component: NewIban,
    meta:{'auth': true},
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: ContractsList,
    meta:{'auth': true},
  },
  {
    path: '/new-contract',
    name: 'New Contract',
    component: NewContract,
    meta:{'auth': true},
  },
  {
    path: '/show-contract/:id',
    name: 'Show Contract',
    component: ShowContract,
    meta:{'auth': true},
  },
  {
    path: '/categories',
    name: 'Categories',
    component: CategoryList,
    meta:{'auth': true},
  },
  {
    path: '/new-category',
    name: 'New Category',
    component: NewCategory,
    meta:{'auth': true},
  },
  {
    path: '/edit-category/:id',
    name: 'Edit Category',
    component: EditCategory,
    meta:{'auth': true},
  },
  {
    path: '/sub-categories',
    name: 'Sub Categories',
    component: SubCategoryList,
    meta:{'auth': true},
  },
  {
    path: '/new-sub-category',
    name: 'New Sub Category',
    component: NewSubCategory,
    meta:{'auth': true},
  },
  {
    path: '/edit-sub-category/:id',
    name: 'Edit Sub Category',
    component: EditSubCategory,
    meta:{'auth': true},
  },
  {
    path: '/brands',
    name: 'Brands',
    component: BrandList,
    meta:{'auth': true},
  },
  {
    path: '/new-brand',
    name: 'New Brand',
    component: NewBrand,
    meta:{'auth': true},
  },
  {
    path: '/taxes',
    name: 'Taxes',
    component: TaxList,
    meta:{'auth': true},
  },
  {
    path: '/new-tax',
    name: 'New Tax',
    component: NewTax,
    meta:{'auth': true},
  },
  {
    path: '/products',
    name: 'Products',
    component: ProducList,
    meta:{'auth': true},
  },
  {
    path: '/new-product',
    name: 'New Product',
    component: NewProduct,
    meta:{'auth': true},
  },
  {
    path: '/edit-product/:id',
    name: 'Edit Product',
    component: EditProduct,
    meta:{'auth': true},
  },
  {
    path: '/show-product/:id',
    name: 'Show Product',
    component: ShowProduct,
    meta:{'auth': true},
  },
  {
    path: '/product-images/:id',
    name: 'Product İmages',
    component: ProductImageList,
    meta:{'auth': true},
  },
  {
    path: '/new-product-image/:id',
    name: 'New Product İmage',
    component: NewProductImage,
    meta:{'auth': true},
  },
  {
    path: '/web-orders',
    name: 'Web Orders',
    component: WebOrderList,
    meta:{'auth': true},
  },
  {
    path: '/edit-web-order/:id',
    name: 'Edit Web Orders',
    component: EditWebOrder,
    meta:{'auth': true},
  },
  {
    path: '/show-web-order/:id',
    name: 'Show Web Orders',
    component: ShowWebOrder,
    meta:{'auth': true},
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/tester',
    name: 'test',
    component: Testpage
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const auth = to.meta.auth
  const user = store.state.user
  if (auth){
    if (user){
      next()
    }else{
      next('/login')
    }
  }else{
    if (to.path === '/login'){
      if (user){
        next('/')
      }else{
        next()
      }
    }else{
      next()
    }
  }

});
export default router
