import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/auth'
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faUser, faHome, faCaretDown, faRightFromBracket, faBars, faXmark, faSpinner, faCircleCheck,faCircleInfo,faTriangleExclamation,faCircleXmark,faLayerGroup,faList,faBarcode,faEnvelope,faClock,faCartFlatbed,faTruckFast,faShop,faLink,faSquarePlus,faTrashCan,faPenToSquare,faEye,faMinus,faSquareCaretRight,faSquareCaretLeft,faCircleLeft,faPhotoFilm,faBold,faItalic,faUnderline,faAlignLeft,faAlignCenter,faAlignRight,faListUl,faListOl,faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

library.add(faUser, faHome,faCaretDown,faRightFromBracket,faBars,faXmark,faSpinner,faCircleCheck,faCircleInfo,faTriangleExclamation,faCircleXmark,faLayerGroup,faList,faBarcode,faEnvelope,faClock,faCartFlatbed,faTruckFast,faShop,faLink,faSquarePlus,faTrashCan,faPenToSquare,faEye,faMinus,faSquareCaretRight,faSquareCaretLeft,faCircleLeft,faPhotoFilm,faBold,faItalic,faUnderline,faAlignLeft,faAlignCenter,faAlignRight,faListUl,faListOl,faMagnifyingGlass)
createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).component('EasyDataTable',Vue3EasyDataTable).mount('#app')