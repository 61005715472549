<template>
  <div class="editor ">
    <div class="editor-tools flex mt-3 mb-3 p-2 border-2 border-black">
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyStyle('bold')"><font-awesome-icon :icon="['fa', 'bold']"/></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyStyle('italic')"><font-awesome-icon :icon="['fa', 'italic']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyStyle('underline')"><font-awesome-icon :icon="['fa', 'underline']" /></button>
      <input class="w-8 h-6 border-2 border-black flex mr-2" @change="applyColor(selectedColor)" type="color" v-model="selectedColor">
      <button class="w-8 h-6 p-1 border-2 border-black flex mr-2" @click="addList('ul')"><font-awesome-icon :icon="['fas', 'list-ul']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex mr-2" @click="addList('ol')"><font-awesome-icon :icon="['fas', 'list-ol']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyTextAlign('left')"><font-awesome-icon :icon="['fa', 'align-left']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyTextAlign('center')"><font-awesome-icon :icon="['fa', 'align-center']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="applyTextAlign('right')"><font-awesome-icon :icon="['fa', 'align-right']" /></button>
      <button class="w-8 h-6 p-1 border-2 border-black flex justify-center content-center mr-2" @click="addLink"><font-awesome-icon :icon="['fa', 'link']" /></button>
    </div>
    <!-- Düzenlenebilir ve Çıktı Alanı -->
    <div
        class="editor p-4 border-2 border-black rounded outline-none min-h-44 text-left"
        contenteditable="true"
        ref="editor"
        @input="updateContent"
        @mouseup="saveSelection"
        @keyup="saveSelection"
    ></div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const selectedColor = ref()
const editorRef = ref(null); // Editör referansı
const selectionRange = ref(null); // Seçili metin aralığı
const saveSelection = () => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    selectionRange.value = selection.getRangeAt(0);
  }
}
// Seçimi geri yükle
const restoreSelection = () => {
  const selection = window.getSelection();
  if (selectionRange.value) {
    selection.removeAllRanges();
    selection.addRange(selectionRange.value);
  }
}
// Stil uygula
const applyStyle = (style) => {
  restoreSelection();
  document.execCommand(style);
};
// Renk uygula
const applyColor = (color) => {
  restoreSelection();
  document.execCommand("foreColor", false, color);
};
// Bağlantı ekle
const addLink = () => {
  const url = prompt("Bağlantı URL'si:");
  if (url) {
    restoreSelection();
    document.execCommand("createLink", false, url);
  }
};

// Liste ekle
const addList = (type) => {
  restoreSelection();

  const list = document.createElement(type); // ul veya ol oluştur
  const listItem1 = document.createElement("li");
  const listItem2 = document.createElement("li");
  listItem1.textContent = "Liste elemanı 1";
  listItem2.textContent = "Liste elemanı 2";

  list.appendChild(listItem1);
  list.appendChild(listItem2);

  // Listeyi seçili alana ekle veya sonuna ekle
  if (selectionRange.value) {
    selectionRange.value.deleteContents();
    selectionRange.value.insertNode(list);
  } else if (editorRef.value) {
    editorRef.value.appendChild(list);
  }

  updateContent();
};
// Metni hizalama
const applyTextAlign = (align) => {
  restoreSelection();
  document.execCommand("justify" + align);
};
// İçeriği güncelle
const updateContent = () => {
  // Gerekirse düzenlenebilir alandaki içeriği işle
  //console.log(editorRef.value.innerHTML);
};
</script>
<style scoped>
</style>